import styled from '@emotion/styled'

export const StyledHomePage = styled.div`
  .feedback {
    padding-bottom: 0;
    h1 {
      font-weight: 400;
    }
  }

  .shopbytiktok,
  .shopbycollection {
    margin: 110px auto 0;
    a {
      text-decoration: none;
    }
    .title {
      font-weight: 400;
    }
  }

  @media screen and (max-width: 1440px) {
    .shopbytiktok {
      > div {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .container {
      padding: 0 0.9375rem;
    }

    .shopbytiktok {
      padding: 0;
    }

    .feedback {
      padding-bottom: 1.5rem;
    }

    .shopbycollection {
      padding: 0;

      .categorylist {
        > div {
          margin-bottom: 20px;
          flex: 0 0 100%;
        }
      }
    }
  }
`
